@import "../../styles/variables.scss";

$item-info-height: 111px;
$item-width: 223.4px;

.container {
  padding: 0 20px;
}

.ms-dropdown {
  width: 210px;

  :global(.ms-Dropdown-title) {
    border-left: none;
    border-top: none;
    border-right: none;
    color: #201f1e;
  }

  i {
    color: #201f1e;
  }
}

.empty {
  color: #888888;
}

.control {
  display: flex;
  margin-bottom: 24px;
  gap: 16px;

  .button {
    background-color: $attention-color;
    color: #323130;
    padding: 0 12px;
    border: 0;

    i {
      font-size: 10px;
      font-weight: 600;
    }
  }
  .filters {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .dropdown {
      display: flex;
      align-items: center;
      gap: 10px;

      label {
        margin-bottom: 4px;
      }
    }

    .search-input-field {
      width: 200px;

      input {
        padding-left: 36px;
        padding-right: 8px;
      }
    }

    .search-icon {
      position: absolute;
      bottom: 6px;
      left: 12px;
      top: auto;
      font-size: 16px;
      line-height: 18px;
      color: $theme-primary;
    }
  }
}

.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 16px;
}

.grid-item {
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1),
    0px 1.6px 3.6px rgba(0, 0, 0, 0.13);

  cursor: pointer;

  height: calc($item-info-height + 100px);
  width: $item-width;
  position: relative;
  background-color: $neutral-lighter;

  .item-info {
    background-color: white;
    position: absolute;
    height: $item-info-height;
    width: $item-width;
    bottom: 0;

    padding: 8px 12px 0px 12px;

    .large-header {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      .inline-header {
        font-size: 12px;
        line-height: 140%;
        color: #8a8886;
        margin-left: 8px;
        margin-right: 8px;
        flex: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .icon {
        border-radius: 50%;
        height: 25px;
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          color: #fff;
          width: 16px;
          height: 16px;

          svg {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 16px;
            height: 16px;
          }
        }
      }

      .unpinned {
        color: $neutral-tertiary-alt;
      }
      .pinned {
        color: $theme-primary;
      }
    }
  }

  .item-image {
    height: 100%;
    .item-image-frame {
      width: 100%;
      height: 100px;
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .description {
    font-size: 14px;
    line-height: 100%;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: $neutral-primary;
    padding-bottom: 12px;
  }

  .alt-description {
    border-top: 1px solid $separator-color-2;
    padding-top: 12px;
    font-size: 12px;
    line-height: 16px;
    color: #a19f9d;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > * {
      align-self: center;
    }

    > i {
      height: 15px;
      > svg {
        height: 15px;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .control {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 10px;
    .dropdown {
      align-items: unset;
      justify-content: space-between;
      width: 100%;
    }

    .button {
      width: 100%;
    }
  }

  .grid {
    display: block;

    .grid-item {
      width: 100%;

      .item-info {
        width: 100%;
      }

      margin-bottom: 16px;
    }
  }
}

.grid-item:hover {
  .item-info {
    background-color: $neutral-lighter-alt;
  }
}

.grid-item.create {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background-color: $neutral-lighter-alt;

  .icon {
    margin-right: 12px;
  }
}

.grid-item.create:hover {
  background-color: $neutral-lighter;
}

.grid-item.skeleton {
  display: inline-block;
  overflow: hidden;
  position: relative;
  cursor: default;

  .large-header {
    .icon {
      background-color: $neutral-lighter;
    }

    .inline-header {
      background-color: $neutral-lighter;
      height: 19px;
      width: 100px;
    }
  }

  .description {
    background-color: $neutral-lighter;
    height: 24px;
    margin-bottom: 12px;
  }

  .alt-description {
    background-color: $neutral-lighter;
    height: 18px;
    width: 150px;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 1.5s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.grid-item.skeleton:hover {
  background-color: inherit;
}

.paginator {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  padding-bottom: 22px;
}

.icon.combined {
  background-color: #faae68;
}

.icon.fixed-layout {
  background-color: #ff6d7d;
}

.icon.custom-model {
  background-color: #25d0b1;
}
.icon.prebuilt {
  background-color: #6888fa;
}

.extractor-card-big-icon {
  text-align: center;
  background-color: white;
  margin: 20px;

  > i {
    margin-top: 22px;
  }
}
