@import "./variables.scss";

html,
body {
  height: 100vh;
  background-color: $bgcolor;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Segoe UI", "Segoe UI Web (West European)", -apple-system,
    BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none;
}

a,
a:visited,
a:active {
  color: inherit;
}

a:hover {
  color: $light-color;
}

div.react-toast-notifications__container {
  overflow: hidden;
}

.wide-toast {
  width: 400px;
}

.google-api-toast {
  width: 600px;
  margin-left: -140px;
  white-space: pre-line;
}

.payment-required-toast {
  margin-top: 64px;
  width: 600px;
}

.ms-Dialog-title {
  font-size: 18px;
}
