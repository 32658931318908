@import "../../styles/variables.scss";

.arrow-bullet {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  position: relative;
}

.arrow-bullet-is-last:before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  height: 50%;
  width: 1px;
  border-left: 1px solid #25d0b1;
}

.arrow-bullet-not-last {
  border-left: 1px solid #25d0b1;
}
.field-item {
  background-color: #fff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  height: 52px;
  border: 1px solid #edebe9;
  border-radius: 4px;

  &:hover {
    background-color: #f3f2f1;
  }

  .field-item-name {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #201f1e;
    margin-left: 12px;
  }

  .field-item-type {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-left: 12px;
    color: #201f1e;
    opacity: 0.4;
  }

  .field-item-left {
    display: flex;
    align-items: flex-start;
    margin-top: 16px;
    margin-bottom: 16px;
    flex-direction: column;
  }

  .field-item-right {
    display: flex;
    flex-direction: row;
    align-items: center;

    .trash-icon {
      margin-left: 12px;
      margin-right: 15px;
    }
  }
}

.field-item-selected {
  border: 1px solid #25d0b1;
}

.field-item-error {
  border: 1px solid #a4262c;

  .field-item-name {
    color: #a4262c;
  }
}

.field-item-no-sub-field {
  color: #a19f9d;
  height: 32px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  padding-left: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
}

.field-item-sub-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  padding-right: 14px;

  .field-item-sub-field-title {
    color: #201f1e;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-left: 14px;
    flex: 1 1 0;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;
  }

  .field-item-sub-field-type {
    flex-grow: 0;
    color: #a19f9d;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-left: 8px;
  }
}
.document-type-panel {
  padding: 12px 20px 16px 20px;
  background: #faf9f8;
}
.document-type-panel-content {
  display: flex;
  flex-direction: column;
  align-items: left;

  .document-type-panel-content-title {
    color: #201f1e;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
  }

  .document-type-panel-content-text-field {
    max-width: 300px;
    margin-bottom: 6px;
  }

  .document-type-panel-content-help-text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #605e5c;
  }
}

.fields-panel {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 12px 20px 16px 20px;
  background: #faf9f8;
  .fields-panel-content {
    display: flex;
    flex-direction: column;
    align-items: left;
    flex: 1;
  }

  .fields-panel-content-scrollable {
    flex: 1 1 0;
    overflow-y: auto;
    padding: 0px 0px;
  }
  .fields-panel-content-help-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: flex-end;
    color: #605e5c;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .fields-panel-content-field-list {
    padding: 12px 0px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .fields-panel-content-select-template-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 74px;
    width: 100%;
    order: 1;
    padding: 0px 0px;
    gap: 10px;
  }
}

.field-schema-editor {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.not-saved-prompt-dialog-button-actions {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
}

.field-schema-editor-panel-header {
  margin: 0 20px;
}
.templates-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  background: #faf9f8;

  .template-panel-heading {
    color: #201f1e;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }

  .templates-choice-group {
    border: 1px solid #edebe9;
    background: #fff;
    margin-top: 8px;

    :global(.ms-ChoiceField) {
      padding: 8px 12px;
      margin-top: 0px;
    }

    :global(.ms-ChoiceField:nth-child(even)) {
      background: #faf9f8;
    }
  }

  .help-text {
    margin-top: 16px;
    color: #201f1e;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }

  .full-width-button {
    width: 100%;
    margin-top: 8px;
  }

  .instruction {
    margin-top: 20px;
    color: #605e5c;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    width: 100%;
    text-align: center;
  }
}

.field-schema-editor-panel-content {
  padding: 0px 0px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.field-schema-editor-page-header-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
}

.split-view {
  width: 100%;
  height: 100%;
  flex-shrink: 1;
  display: flex;
  flex-direction: row;
  gap: 0px;

  .split-view-left {
    max-width: 420px;
    min-width: 330px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .split-view-right {
    flex-grow: 1;
  }
}

.no-field-placeholder {
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  color: #605e5c;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.template-preview {
  padding: 20px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .template-preview-scrollable {
    flex: 1 1 0;
    overflow-y: auto;
  }

  .template-preview-title {
    margin-bottom: 12px;
    color: #201f1e;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
}

.editor-panel {
  display: flex;
  flex-direction: column;
  flex: 1;

  .editor-panel-content-divider {
    height: 4px;
    border-top: 1px solid var(--border-colors-divider, #edebe9);
    border-bottom: 1px solid var(--border-colors-divider, #edebe9);
    background: #f3f3f3;
  }
}
