/* linaria:data-grid-overlay-editor-style_115vs9w.linaria.css */
.d1t1th9s {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
  --overlay-top:var(--d1t1th9s-0);
  left: var(--d1t1th9s-1);
  top: var(--d1t1th9s-2);
  min-width: var(--d1t1th9s-3);
  min-height: var(--d1t1th9s-4);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 400px;
  max-height: calc(100vh - var(--d1t1th9s-5));
  font-family: var(--gdg-font-family);
  font-size: var(--gdg-editor-font-size);
  text-align: start;
}
@-webkit-keyframes glide_fade_in-d1t1th9s {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}
@keyframes glide_fade_in-d1t1th9s {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}
.d1t1th9s.gdg-style {
  border-radius: 2px;
  background-color: var(--gdg-bg-cell);
  box-shadow:
    0 0 0 1px var(--gdg-accent-color),
    0px 0px 1px rgba(62, 65, 86, 0.4),
    0px 6px 12px rgba(62, 65, 86, 0.15);
  -webkit-animation: glide_fade_in-d1t1th9s 60ms 1;
  animation: glide_fade_in-d1t1th9s 60ms 1;
}
.d1t1th9s.pad {
  padding: var(--d1t1th9s-6) 8.5px 3px;
}
.d1t1th9s .clip-region {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 2px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.d1t1th9s .clip-region .gdg-growing-entry {
  height: 100%;
}
.d1t1th9s .clip-region input.gdg-input {
  width: 100%;
  border: none;
  border-width: 0;
  outline: none;
}
.d1t1th9s .clip-region textarea.gdg-input {
  border: none;
  border-width: 0;
  outline: none;
}

/* linaria:scrolling-data-grid_zcytub.linaria.css */
.m15w2ly5 {
  position: absolute;
  right: 44px;
  bottom: 44px;
  background-color: var(--gdg-bg-cell);
  background: linear-gradient(var(--gdg-bg-cell), var(--gdg-bg-cell-medium));
  border-radius: 4px;
  z-index: 1;
  box-shadow: 0 0 0 1px var(--gdg-border-color), 0 2px 5px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}
.m15w2ly5 .header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 4px;
  background-color: var(--gdg-bg-header);
  box-shadow: 0 0 0 1px var(--gdg-border-color);
}
.m15w2ly5 .locationMarker {
  position: absolute;
  border: 1px solid var(--gdg-accent-color);
  background-color: var(--gdg-accent-light);
}

/* linaria:infinite-scroller_1qseojb.linaria.css */
.s1jz82f8 .dvn-scroller {
  overflow: var(--s1jz82f8-0);
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.s1jz82f8 .hidden {
  visibility: hidden;
}
.s1jz82f8 .dvn-scroll-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  pointer-events: none;
}
.s1jz82f8 .dvn-scroll-inner > * {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.s1jz82f8 .dvn-scroll-inner .dvn-spacer {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.s1jz82f8 .dvn-scroll-inner .dvn-stack {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.s1jz82f8 .dvn-underlay > * {
  position: absolute;
  left: 0;
  top: 0;
}
.s1jz82f8 canvas {
  outline: none;
}
.s1jz82f8 canvas * {
  height: 0;
}

/* linaria:data-grid-search-style_jgiiup.linaria.css */
.sxep88s {
  position: absolute;
  top: 4px;
  right: 20px;
  background-color: var(--gdg-bg-cell);
  color: var(--gdg-text-dark);
  padding: 8px;
  border: 1px solid var(--gdg-border-color);
  border-radius: 6px;
  font-size: var(--gdg-editor-font-size);
  -webkit-transform: translateX(var(--sxep88s-0));
  -ms-transform: translateX(var(--sxep88s-0));
  transform: translateX(var(--sxep88s-0));
  -webkit-transition: -webkit-transform 0.15s;
  -webkit-transition: transform 0.15s;
  transition: transform 0.15s;
}
.sxep88s .search-bar-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.sxep88s .search-status {
  padding-top: 4px;
  font-size: 11px;
}
.sxep88s .search-progress {
  position: absolute;
  height: 4px;
  left: 0;
  bottom: 0;
  background-color: var(--gdg-text-light);
}
.sxep88s input {
  width: 220px;
  color: var(--gdg-textDark);
  background-color: var(--gdg-bg-cell);
  border: none;
  border-width: 0;
  outline: none;
}
.sxep88s button {
  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  color: var(--gdg-text-medium);
}
.sxep88s button:hover {
  color: var(--gdg-text-dark);
}
.sxep88s button .button-icon {
  width: 16px;
  height: 16px;
}
.sxep88s button:disabled {
  opacity: 0.4;
  pointer-events: none;
}

/* linaria:group-rename_yc2zuw.linaria.css */
.r1kzy40b {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  border: none;
  outline: none;
  background-color: var(--gdg-bg-header-has-focus);
  border-radius: 9px;
  padding: 0 8px;
  box-shadow: 0 0 0 1px var(--gdg-border-color);
  color: var(--gdg-text-group-header);
  min-height: var(--r1kzy40b-0);
  font: var(--gdg-header-font-style) var(--gdg-font-family);
}
.c1sqdbw3 {
  padding: 0 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--gdg-bg-header);
}

/* linaria:data-grid-container_gfrgfn.linaria.css */
.wzg2m5k {
  position: relative;
  min-width: 10px;
  min-height: 10px;
  max-width: 100%;
  max-height: 100%;
  width: var(--wzg2m5k-0);
  height: var(--wzg2m5k-1);
  overflow: hidden;
  overflow: clip;
  contain: strict;
}
.wzg2m5k > :first-child {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

/* linaria:bubbles-overlay-editor-style_1022dut.linaria.css */
.b1bqsp5z {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.b1bqsp5z .boe-bubble {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  padding: 0 8px;
  height: 20px;
  background-color: var(--gdg-bg-bubble);
  color: var(--gdg-text-dark);
  margin: 2px;
}
.b1bqsp5z textarea {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 0px;
  height: 0px;
  opacity: 0;
}

/* linaria:drilldown-overlay-editor_1gv6dry.linaria.css */
.df2kt4a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.df2kt4a .doe-bubble {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  padding: 0 8px;
  height: 24px;
  background-color: var(--gdg-bg-cell);
  color: var(--gdg-text-dark);
  margin: 2px;
  border-radius: 6px;
  box-shadow: 0 0 1px rgba(62, 65, 86, 0.4), 0 1px 3px rgba(62, 65, 86, 0.4);
}
.df2kt4a .doe-bubble img {
  height: 16px;
  object-fit: contain;
  margin-right: 4px;
}
.df2kt4a textarea {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 0px;
  height: 0px;
  opacity: 0;
}

/* linaria:image-overlay-editor-style_sojiwi.linaria.css */
.i1eozt10 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}
.i1eozt10 .centering-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}
.i1eozt10 .centering-container img,
.i1eozt10 .centering-container canvas {
  max-height: calc(100vh - var(--overlay-top) - 20px);
  object-fit: contain;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.i1eozt10 .centering-container canvas {
  max-width: 380px;
}
.i1eozt10 .edit-icon {
  position: absolute;
  top: 12px;
  right: 0;
  width: 48px;
  height: 48px;
  color: var(--gdg-accent-color);
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.i1eozt10 .edit-icon > * {
  width: 24px;
  height: 24px;
}
.i1eozt10 textarea {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 0px;
  height: 0px;
  opacity: 0;
}

/* linaria:markdown-container_kvmc63.linaria.css */
.mlbeo71 {
  word-break: break-word;
  -webkit-touch-callout: default;
  padding-top: 6px;
}
.mlbeo71 > * {
  margin: 0;
}
.mlbeo71 *:last-child {
  margin-bottom: 0;
}
.mlbeo71 p img {
  width: 100%;
}

/* linaria:growing-entry-style_ycxqui.linaria.css */
.ijuk0po {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 0px;
  resize: none;
  white-space: pre-wrap;
  min-width: 100%;
  overflow: hidden;
  border: 0;
  background-color: transparent;
  font-size: var(--gdg-editor-font-size);
  line-height: 16px;
  font-family: var(--gdg-font-family);
  color: var(--gdg-text-dark);
  padding: 0;
  margin: 0;
}
.ijuk0po::-webkit-input-placeholder {
  color: var(--gdg-text-light);
}
.ijuk0po::-moz-placeholder {
  color: var(--gdg-text-light);
}
.ijuk0po:-ms-input-placeholder {
  color: var(--gdg-text-light);
}
.ijuk0po::placeholder {
  color: var(--gdg-text-light);
}
.invalid .ijuk0po {
  -webkit-text-decoration: underline;
  text-decoration: underline;
  -webkit-text-decoration-color: #d60606;
  text-decoration-color: #d60606;
}
.saq3p5l {
  visibility: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  min-width: 100%;
  font-size: var(--gdg-editor-font-size);
  line-height: 16px;
  font-family: var(--gdg-font-family);
  color: var(--gdg-text-dark);
  padding: 0;
  margin: 0;
  padding-bottom: 2px;
}
.gf8vzix {
  position: relative;
  margin-top: 6px;
}

/* linaria:markdown-overlay-editor-style_nbv04l.linaria.css */
.mdwzdl1 {
  min-width: var(--mdwzdl1-0);
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  color: var(--gdg-text-dark);
}
.mdwzdl1 .gf8vzix {
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  min-width: 0;
}
.mdwzdl1 .spacer {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.mdwzdl1 .edit-icon {
  position: relative;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--gdg-accent-color);
  padding: 0;
  height: 24px;
  width: 24px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: all "0.125s ease";
  transition: all "0.125s ease";
  border-radius: 6px;
}
.mdwzdl1 .edit-icon > * {
  width: 16px;
  height: 16px;
}
.mdwzdl1 .edit-hover:hover {
  background-color: var(--gdg-accent-light);
  -webkit-transition: background-color 150ms;
  transition: background-color 150ms;
}
.mdwzdl1 .checkmark-hover:hover {
  color: #ffffff;
  background-color: var(--gdg-accent-color);
}
.mdwzdl1 .md-edit-textarea {
  position: relative;
  top: 0px;
  left: 0px;
  width: 0px;
  height: 0px;
  margin-top: 25px;
  opacity: 0;
  padding: 0;
}
.mdwzdl1 .ml-6 {
  margin-left: 6px;
}

/* linaria:number-overlay-editor-style_1i1z3n.linaria.css */
.n1czszh3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 6px 0 3px;
  color: var(--gdg-text-dark);
}
.n1czszh3 > input {
  font-size: var(--gdg-editor-font-size);
  padding: 0;
  font-family: var(--gdg-font-family);
  color: var(--gdg-text-dark);
  background-color: var(--gdg-bg-cell);
}

/* linaria:uri-overlay-editor-style_nguq6d.linaria.css */
.uf0sjo8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 21px;
}
.uf0sjo8 .link-area {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  cursor: pointer;
  margin-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--gdg-link-color);
  -webkit-text-decoration: underline !important;
  text-decoration: underline !important;
}
.uf0sjo8 .edit-icon {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 32px;
  color: var(--gdg-accent-color);
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.uf0sjo8 .edit-icon > * {
  width: 24px;
  height: 24px;
}
.uf0sjo8 textarea {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 0px;
  height: 0px;
  opacity: 0;
}
