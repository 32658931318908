.prompt,
.task-name,
.settings {
  padding-top: 10px;
}

.settings {
  overflow: auto;
  > div {
    float: left;
    width: 50%;
    padding: 10px;
  }
}

.prompt-selection {
  display: flex;

  .prompt-selection-dropdown {
    flex: 1;
    padding-right: 10px;
  }

  .delete-prompt-button {
    i > svg {
      height: 16px;
    }
  }
}

.explaination {
  margin-top: 4px;
  font-size: 10pt;
  color: #666;
}

.description {
  font-size: 10pt;
  color: #666;
}
