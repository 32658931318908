@import "../../node_modules/@fluentui/react/dist/sass/_References.scss"; /* stylelint-disable-line */

@mixin icon-size($size) {
  background-repeat: no-repeat;
  background-size: $size $size;
}

@mixin icon-frame-size($size) {
  @include icon-size($size);
  width: $size;
  height: $size;
}

@mixin image-frame-size($w, $h) {
  background-repeat: no-repeat;
  background-size: $w $h;
  width: $w;
  height: $h;
}

@mixin no-select {
  /* stylelint-disable */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
  /* stylelint-enable */
}

@mixin cardBoxBorderRadius {
  border-radius: 2px;
}
