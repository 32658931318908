$bgcolor: #fff;
$primary-text-color: #fff;
$dark-color: #201f1e;
$light-color: #908c29;
$alert-color: #f63c51;
$light-alert-color: #ee8a96;
$disabled-color: #7f8c8d;
$button-disabled-color: #cccccc;
$header-color: #8c8b6d;
$separator-color: #ededed;
$input-error-color: #a4262c;
$light-gray-color: #898989;
$red-icon-color: #eb5757;

$hover-tab: #f7f7e1;
$tab-background: #dedecc;
$code-background: #f7f7f7;

$form-corner-radius: 15px;
$form-bgcolor: #fff;
$form-title-color: #fff;
$form-header-bgcolor: #aa0;
$form-button-color: #aa0;
$form-button-text-color: #fff;
$form-button-active-color: #c9c940;
$form-focus-color: rgba(220, 215, 62, 0.2);

$detection-region-field-inspector-border: #cfcfa5;

$left-bar-full-width: 260px;
$left-bar-full-width-small-device: 350px;
$left-bar-collapsed-width: 16px;

$form-extractor-logo-color: #5ecebd;

$border-color: rgba(0, 0, 0, 0.2);
$separator-color-1: #c5c5c6;
$card-border-radius: 2px;
$separator-color-2: #edebe9;
$text-field-border-color: #8f8e8c;
$tab-bar-separator: #cccccc;
$tab-bar-right-space: 24px;
$attention-color: #ffd633;
$darker-primary-color: #2ec293;
$even-darker-primary-color: #1d9f87;

$theme-primary: #25d0b1;
$theme-lighter-alt: #f5fdfc;
$theme-lighter: #d7f8f2;
$theme-light: #b6f1e6;
$theme-tertiary: #74e3cf;
$theme-secondary: #3cd7ba;
$theme-dark-alt: #22bca0;
$theme-dark: #1d9f87;
$theme-darker: #157563;
$neutral-lighter-alt: #faf9f8;
$neutral-lighter: #f3f2f1;
$neutral-light: #edebe9;
$neutral-quaternary-alt: #e1dfdd;
$neutral-quaternary: #d0d0d0;
$neutral-tertiary-alt: #c8c6c4;
$neutral-tertiary: #b4b2b0;
$neutral-secondary: #9b9997;
$neutral-primary-alt: #83817e;
$neutral-primary: #201f1e;
$neutral-dark: #52504e;
$black: #3a3836;
$white: #ffffff;
