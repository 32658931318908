@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

.text-headline-large {
  color: var(--M3-black, #000);
  font-family: Inter, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
}

.text-headline-medium {
  color: var(--grey-700, #131315);
  font-family: Inter, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
}

.text-headline-small {
  color: var(--M3-black, #000);
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
}

.text-title-medium {
  color: var(--M3-black, #000);
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.text-label-large {
  color: var(--M3-black, #000);
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.1px;
}

.text-label-medium {
  color: var(--M3-black, #000);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.5px;
}

.text-body-large {
  color: var(--M3-black, #000);
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.text-body-medium {
  color: var(--M3-black, #000);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.text-body-small {
  color: var(--M3-black, #000);
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.card {
  border-radius: 12px;
  border: 1px solid var(--bolder-border-primary, #dce3e1);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.09);
  background: #fff;
}

@config "../../tailwind-v2.config.js";

@tailwind components;
@tailwind utilities;
